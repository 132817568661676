import { CheckoutErrorCode, CheckoutUserError, CustomerErrorCode, CustomerUserError } from '@ts/shopify-storefront-api';
import { UserError } from '@ts/shopify-admin-api';
import { ValidationError } from '.';

export type UserErrors = Array<CheckoutUserError | CustomerUserError | UserError>;

export type UserErrorCode = CustomerErrorCode | CheckoutErrorCode | null | undefined;

const getCustomMessage = (code: UserErrorCode, message: string) => {
	switch (code) {
		case CustomerErrorCode.UnidentifiedCustomer:
			message = 'Cannot find an account that matches the provided credentials';
			break;
	}
	return message;
};

export const throwUserErrors = (errors?: UserErrors) => {
	if (errors && errors.length) {
		console.error('User errors: ', JSON.stringify(errors));
		throw new ValidationError({
			errors: errors.map(error => {
				if (error.__typename !== 'UserError') {
					return {
						code: error.code ?? 'validation_error',
						message: getCustomMessage(error.code, error.message),
					};
				}
				return {
					code: 'validation_error',
					message: getCustomMessage(null, error.message),
				};
			}),
		});
	}
};

export default throwUserErrors;
