const customerRecoverPasswordMutation = /* GraphQL */ `
	mutation customerRecoverPassword($email: String!) {
		customerRecover(email: $email) {
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

export default customerRecoverPasswordMutation;
