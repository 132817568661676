import { Mutation } from '@ts/shopify-storefront-api';
import fetchStorefrontApi from '../fetch-storefront-api';
import customerRecoverPasswordMutation from '../mutations/customer-recover-password';
import throwUserErrors from '../throw-user-errors';

/**
 * Sends a password recovery email to the user with the provided email.
 *
 * The recovery email contains a link with the form:
 *
 * https://paireyewear.com/account/reset/[CUSTOMER_ID]/[RESET_TOKEN]
 */
const recoverPassword = async (email: string) => {
	const mutation: Mutation = await fetchStorefrontApi(customerRecoverPasswordMutation, { variables: { email } });

	const { customerRecover } = mutation;

	throwUserErrors(customerRecover?.customerUserErrors);

	return null;
};

export default recoverPassword;
