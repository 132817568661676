import { Attention, Flex, Paragraph } from '@components';
import styles from './Alert.module.scss';

type AlertProps = {
	message?: string;
	info?: boolean;
};

const Alert = ({ message, info }: AlertProps) => {
	if (info) {
		const defaultInfoMessage = `We've sent you an email with a link to update your password.`;
		return (
			<Flex gap={2} className={info ? styles.info : styles.hidden}>
				<Attention height={20} width={20} label='Attention' fill='white' />
				<Paragraph>{message ? message : defaultInfoMessage}</Paragraph>
			</Flex>
		);
	} else {
		return (
			<Flex gap={2} className={message ? styles.error : styles.hidden}>
				<Attention height={20} width={20} label='Attention' fill='white' />
				<Paragraph>{message}</Paragraph>
			</Flex>
		);
	}
};

export default Alert;
